import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

// Map studio routes to feature names
const ROUTE_TO_FEATURE = {
  "/vox-studio": "vox-studio",
  "/sfx-studio": "sfx-studio",
  "/music-studio": "music-studio",
};

// Map features to display names
const FEATURE_DISPLAY_NAMES = {
  "vox-studio": "Vox Studio",
  "sfx-studio": "SFX Studio",
  "music-studio": "Music Studio",
};

function SubscriptionGuard({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userSubscription, setUserSubscription] = useState(null);
  const [userFeatures, setUserFeatures] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch user subscription data
  const fetchUserData = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE,
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const subscriptionTier = response.data.subscription_tier || "Free";

      // Store subscription tier in local storage
      localStorage.setItem("subscriptionTier", subscriptionTier);

      setUserSubscription({
        name: subscriptionTier,
        features: response.data.subscription_tier_info?.features || [],
      });
      setUserFeatures(response.data.subscription_features || []);
      return response.data.subscription_features || [];
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };

  // Check access for current route
  const checkRouteAccess = (features) => {
    const requiredFeature = ROUTE_TO_FEATURE[location.pathname];
    return !requiredFeature || features.includes(requiredFeature);
  };

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);
      setHasAccess(false);

      const features = await fetchUserData();
      setHasAccess(checkRouteAccess(features));
      setLoading(false);
    };

    checkAccess();
  }, [getAccessTokenSilently, location.pathname]);

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <MDTypography variant="h4">Loading...</MDTypography>
      </MDBox>
    );
  }

  if (!hasAccess) {
    const currentFeature =
      FEATURE_DISPLAY_NAMES[ROUTE_TO_FEATURE[location.pathname]] || "this feature";

    return (
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
        p={3}
      >
        <Card sx={{ p: 4, maxWidth: "600px", width: "100%" }}>
          <MDTypography variant="h3" mb={2}>
            Premium Feature
          </MDTypography>

          <MDTypography variant="body1" mb={4}>
            {currentFeature} is not available with your current subscription
            {userSubscription && ` (${userSubscription.name})`}.
          </MDTypography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => window.open("https://www.jellybeanai.co/pricing", "_blank")}
              >
                View Pricing Plans
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton variant="outlined" color="info" onClick={() => navigate("/profile")}>
                Manage Subscription
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }

  return children;
}

// Setting default values for the props of SubscriptionGuard
SubscriptionGuard.defaultProps = {
  children: false,
};

// Typechecking props for the SubscriptionGuard
SubscriptionGuard.propTypes = {
  children: PropTypes.node,
};

export default SubscriptionGuard;
