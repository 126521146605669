/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import PropTypes from "prop-types";

import { useAuth0 } from "@auth0/auth0-react"; // Add Auth0 hook

import ReactPlayer from "react-player";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";
import axios from "axios";

// @mui material components
import { Tabs, Tab, Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import { InputAdornment, CircularProgress, IconButton } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Typography from "@mui/material/Typography"; // Import Typography
import Button from "@mui/material/Button"; // Import Button

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DataTable from "examples/Tables/DataTable";

// Jellybean React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMaterialUIController, setSimpleMode } from "context";

// @mui icons
import StoryIcon from "@mui/icons-material/AutoStories";
import JokeIcon from "@mui/icons-material/EmojiEmotions";
import AdIcon from "@mui/icons-material/Campaign";
import MultilingualIcon from "@mui/icons-material/Translate";
import MovieIcon from "@mui/icons-material/Theaters";
import GameIcon from "@mui/icons-material/SportsEsports";
import UndoIcon from "@mui/icons-material/Undo";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";

// Dashboard components
import History from "layouts/SoundDesign/components/History";
import MDBadge from "../../../components/MDBadge";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField"; // Assuming you have this import

const voiceAgeLabelMapping = {
  youngadult: "Young Adult",
  middleaged: "Middle Aged",
  senior: "Senior",
};

function VoxStudio({ light }) {
  const [lgValue, setLgValue] = useState(9);
  const [controller, dispatch, transparentNavbar] = useMaterialUIController();
  const { darkMode, simpleMode } = controller;
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme(); // Access the current theme
  const [scriptText, setScriptText] = useState(""); // State to store the script text
  const [rewritePrompt, setRewritePrompt] = useState("");
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [generating, setGenerating] = useState(false); // State for loading indicator
  const [historyData, setHistoryData] = useState([]); // State to store the history data
  const [responseMessage, setResponseMessage] = useState(""); // State to store the API response message
  const [audioUrl, setAudioUrl] = useState(""); // State to store the audio URL for playback
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [voices, setVoices] = useState([]);
  const [filteredVoices, setFilteredVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(""); // Holds selected voice from first dropdown
  const [voiceStyles, setVoiceStyles] = useState([]);
  const [originalScriptText, setOriginalScriptText] = useState(""); // State to store the original script before rewrite
  const [selectedVoiceStyle, setSelectedVoiceStyle] = useState(""); // Holds selected voice style from second dropdown
  const [selectedGender, setSelectedGender] = useState(""); // Holds the selected gender
  const [selectedAccent, setSelectedAccent] = useState(""); // Holds the selected accent
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const { getAccessTokenSilently } = useAuth0(); // Get access token hook from Auth0

  const [rewriting, setRewriting] = useState(false); // State for Rewrite AI button

  const handleSimpleMode = () => {
    const newSimpleModeState = !simpleMode;
    setSimpleMode(dispatch, newSimpleModeState);
    // Save the new theme preference in localStorage
    localStorage.setItem("simpleMode", JSON.stringify(newSimpleModeState));
  };

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleTabChange = (event, newValue) => {
    if (lgValue === 12) {
      setLgValue(9); // Set pt to 4 when a tab is clicked
    } else {
      setLgValue(12);
    }
    setAudioUrl("");
    setActiveTab(newValue); // Update the active tab
  };

  const languages = [
    { code: "ae", name: "Arabic" },
    { code: "cn", name: "Mandarin Chinese" },
    { code: "dk", name: "Danish" },
    { code: "nl", name: "Dutch" },
    { code: "en", name: "English" },
    { code: "ph", name: "Filipino" },
    { code: "fr", name: "French" },
    { code: "de", name: "German" },
    { code: "gr", name: "Greek" },
    { code: "hi", name: "Hindi" },
    { code: "id", name: "Indonesian" },
    { code: "il", name: "Hebrew" },
    { code: "it", name: "Italian" },
    { code: "ja", name: "Japanese" },
    { code: "kr", name: "Korean" },
    { code: "pl", name: "Polish" },
    { code: "pt", name: "Portuguese" },
    { code: "ru", name: "Russian" },
    { code: "es", name: "Spanish" },
    { code: "tr", name: "Turkish" },
    { code: "vn", name: "Vietnamese" },
  ];

  const [pitch, setPitch] = useState(0); // Default value 0 for Pitch
  const [pace, setPace] = useState(1); // Default value 1 for Pace
  const [volume, setVolume] = useState(0); // Default value 0 for Volume

  const sortedLanguages = languages.sort((a, b) => (a.name > b.name ? 1 : -1));

  // Reference to the input field
  const inputRef = useRef(null);

  // Focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field
    }
  }, []); // Empty dependency array to run only once on mount

  // Fetch voices for the first dropdown
  useEffect(() => {
    const fetchVoices = async () => {
      try {
        // Get the access token from Auth0
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
        });

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-voice`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        });

        const sortedVoices = response.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setVoices(sortedVoices);
        setFilteredVoices(sortedVoices); // Initially set filteredVoices to the full list

        // Transform API response into table rows
        const transformedRows = response.data.map((voice) => ({
          name: voice.name,
          gender: <MDTypography variant="caption">{voice.gender}</MDTypography>,
          age: <MDTypography variant="caption">{mapVoiceAge(voice.voiceAge)}</MDTypography>, // Map the voiceAge using the helper function
          accent: <MDTypography variant="caption">{voice.accent}</MDTypography>,
          styles: (
            <div style={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "wrap" }}>
              {voice.voice_styles.map((style, index) => (
                <div
                  key={style.uuid}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <IconButton
                    color="info"
                    size="small"
                    onClick={() => playSample(style.samples_url)}
                    style={{ padding: 0 }} // Remove extra padding around the icon
                  >
                    <PlayArrowIcon />
                  </IconButton>
                  <span>{style.name}</span>
                </div>
              ))}
            </div>
          ),
        }));

        setRows(transformedRows); // Save rows in state to populate the table
      } catch (error) {
        console.error("Error fetching voices:", error);
      }
    };

    fetchVoices();
  }, []);

  // Helper function to map voiceAge to human-readable age ranges
  const mapVoiceAge = (voiceAge) => {
    switch (voiceAge.toLowerCase()) {
      case "youngadult":
        return "Young Adult (18 - 34)";
      case "middleaged":
        return "Middle Aged (35 - 54)";
      case "senior":
        return "Senior (55+)";
      case "child":
        return "Child (0 - 12)";
      case "teen":
        return "Teen (13 - 17)";
      default:
        return "Unknown Age";
    }
  };

  // Function to play the audio sample
  const playSample = (url) => {
    const audio = new Audio(url);
    audio.play();
  };

  // State to hold table rows
  const [rows, setRows] = useState([]);

  // Table data
  const tableData = {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Gender", accessor: "gender", align: "center" },
      { Header: "Age", accessor: "age", align: "center" },
      { Header: "Accent", accessor: "accent", align: "center" },
      { Header: "Styles", accessor: "styles", align: "center" },
    ],
    rows, // Use the dynamic rows fetched from the API
  };

  // Filter voices based on selected gender and accent
  useEffect(() => {
    const filtered = voices.filter((voice) => {
      const genderMatch = selectedGender ? voice.gender === selectedGender : true;
      const accentMatch = selectedAccent ? voice.accent === selectedAccent : true;
      return genderMatch && accentMatch;
    });

    setFilteredVoices(filtered); // Update the filtered voices
  }, [selectedGender, selectedAccent, voices]);

  // Handle voice selection (from the first dropdown)
  const handleVoiceChange = (e) => {
    setSelectedVoice(e.target.value);
    setVoiceStyles([]); // Clear styles when a new voice is selected
    setSelectedVoiceStyle(""); // Clear selected style
  };

  // Handle voice style selection (from the second dropdown)
  const handleVoiceStyleChange = (e) => {
    setSelectedVoiceStyle(e.target.value);
  };

  // Handle gender change
  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
    setVoiceStyles([]); // Clear styles when a new voice is selected
  };

  // Handle accent change
  const handleAccentChange = (e) => {
    setSelectedAccent(e.target.value);
    setVoiceStyles([]); // Clear styles when a new voice is selected
  };

  // Fetch voice styles based on selected voice
  useEffect(() => {
    if (selectedVoice) {
      const fetchVoiceStyles = async () => {
        try {
          // Get the access token from Auth0
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
          });

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/get-voice-style/${selectedVoice}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`, // Include token in Authorization header
              },
            }
          );

          setVoiceStyles(response.data);
        } catch (error) {
          console.error("Error fetching voice styles:", error);
        }
      };

      fetchVoiceStyles();
    }
  }, [selectedVoice]);

  // Fetch history data from /api/query-tts-responses
  const fetchHistory = async () => {
    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/query-tts-responses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  // Fetch history when the component mounts
  useEffect(() => {
    fetchHistory();
  }, []);

  // Function to handle the rewrite action
  const handleRewriteClick = async () => {
    if (scriptText.trim() === "") {
      setResponseMessage("Please enter text to rewrite.");
      openErrorSB();
      return;
    }

    setRewriting(true); // Set rewriting state to true
    setOriginalScriptText(scriptText); // Store the original script before rewrite

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      const payload = {
        text: scriptText, // The script text that needs to be rewritten
        prompt: rewritePrompt, // Use the prompt from the input field
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tts-rewrite-ai`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
          },
        }
      );

      const rewrittenText = response.data.rewritten_text; // Get rewritten text from the API response
      setScriptText(rewrittenText); // Update the text area with the rewritten text
      setResponseMessage("Text rewritten successfully.");
      // openSuccessSB();
    } catch (error) {
      console.error("Error rewriting text:", error);
      setResponseMessage("Failed to rewrite text.");
      openErrorSB();
    } finally {
      setRewriting(false); // Set rewriting state back to false
    }
  };

  // Handle the Generate button click
  const handleGenerateClick = async () => {
    if (scriptText.trim() === "") {
      setResponseMessage("Please enter a Script to generate speech.");
      openErrorSB(); // Open error notification
      return;
    }

    if (selectedVoice.trim() === "") {
      setResponseMessage("Please choose a Voice to generate speech.");
      openErrorSB(); // Open error notification
      return;
    }

    if (selectedVoiceStyle.trim() === "") {
      setResponseMessage("Please choose a Voice Style to generate speech.");
      openErrorSB(); // Open error notification
      return;
    }

    setLoading(true);
    setGenerating(true);
    setResponseMessage(""); // Clear any previous messages

    let retries = 6; // Retry limit
    let success = false; // Track if request succeeds

    while (retries > 0 && !success) {
      try {
        // Get the access token from Auth0
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
        });

        // Prepare the payload
        const payload = {
          speaker_id: selectedVoiceStyle, // Ensure the selected voice is sent
          job_specs: [{ text: scriptText }], // Use the script text as input for TTS generation
          extensions: ["wav"], // Define the required format
          sample_rate: 44100, // Default sample rate
          bit_rate: 128, // Default bit rate
          global_pace: pace, // Default pace
          model_chain: "vox_1_0", // Default model
          language_code: selectedLanguage, // Language for TTS
          global_pitch: pitch, // Default pitch
          auto_pitch: true, // Enable automatic pitch adjustments
          global_volume: volume, // Default volume
        };

        // Submit the script for TTS generation with the token in the headers
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/submit-tts`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
            },
          }
        );

        // If request succeeds
        setResponseMessage("TTS request submitted successfully.");
        fetchHistory(); // Refresh the history after submission
        setLoading(false);
        setGenerating(false);
        // openSuccessSB(); // Open success notification
        success = true; // Mark request as successful
      } catch (error) {
        if (error.response && error.response.status === 429) {
          console.log("Rate limit exceeded, retrying..."); // Log the error
          retries -= 1; // Decrement retry count
          if (retries > 0) {
            await new Promise((resolve) => setTimeout(resolve, 10 * 1000)); // Wait 10 seconds before retrying
          } else {
            setResponseMessage("Rate limit exceeded. Please try again later.");
            openErrorSB(); // Open error notification
          }
        } else {
          // Handle non-429 errors
          // Extract error details from the response
          if (error.response && error.response.data) {
            const { message, subscription_end_date } = error.response.data;

            // Construct a detailed error message
            const errorMessage = subscription_end_date
              ? `${message}. Subscription expired on: ${new Date(
                  subscription_end_date
                ).toLocaleString()}`
              : message;

            // Display the detailed error message
            setResponseMessage(errorMessage);
          } else {
            // Fallback for unexpected errors
            setResponseMessage("Failed to submit TTS request.");
          }
          setLoading(false);
          setGenerating(false);
          openErrorSB(); // Open error notification
          break; // Exit loop for non-429 errors
        }
      }
    }

    setLoading(false);
    setGenerating(false);
  };

  // Handle play action for a specific UUID
  const handlePlayClick = async (uuid, text) => {
    setAudioUrl("");
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-tts-uploads/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const audioUrl = response.data.url; // Get the audio URL from the response
      setAudioUrl(audioUrl); // Store the audio URL in state
      setScriptText(text); // Store the script text in state
      setResponseMessage("Audio is ready for playback.");
      setLoading(false);
      // openSuccessSB(); // Open success notification
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setResponseMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setResponseMessage("Failed to download audio.");
      }
      setLoading(false);
      openErrorSB(); // Open error notification
    }
  };

  // Handle download action for a specific UUID
  const handleDownloadClick = async (uuid) => {
    setLoading(true);
    setResponseMessage(""); // Clear any previous messages

    try {
      // Get the access token from Auth0
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API
      });

      // Send the request with the token in the Authorization header
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-signed-url/jellybeanai-tts-uploads/${uuid}.wav`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );

      const downloadUrl = response.data.url; // Get the download URL from the response

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${uuid}.wav`); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click(); // Programmatically click the link to start download
      document.body.removeChild(link); // Remove the link after the download starts

      setResponseMessage("Download started successfully.");
      // openSuccessSB(); // Open success notification
    } catch (error) {
      // Extract error details from the response
      if (error.response && error.response.data) {
        const { message, subscription_end_date } = error.response.data;

        // Construct a detailed error message
        const errorMessage = subscription_end_date
          ? `${message}. Subscription expired on: ${new Date(
              subscription_end_date
            ).toLocaleString()}`
          : message;

        // Display the detailed error message
        setResponseMessage(errorMessage);
      } else {
        // Fallback for unexpected errors
        setResponseMessage("Failed to download audio.");
      }
      setLoading(false);
      openErrorSB(); // Open error notification
    } finally {
      setLoading(false);
    }
  };

  const pitchMarks = [
    {
      value: -17,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Deeper
        </MDTypography>
      ),
    },
    {
      value: 18,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Higher
        </MDTypography>
      ),
    },
  ];

  const paceMarks = [
    {
      value: 0.5,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Slow
        </MDTypography>
      ),
    },
    {
      value: 1.5,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Fast
        </MDTypography>
      ),
    },
  ];

  const volumeMarks = [
    {
      value: -6,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Soft
        </MDTypography>
      ),
    },
    {
      value: 6,
      label: (
        <MDTypography variant="caption" fontWeight="light">
          Loud
        </MDTypography>
      ),
    },
  ];

  const renderGenerateTab = (
    <MDBox component="form" role="form">
      <MDTypography variant="body2" gutterBottom sx={{ fontSize: "12px" }}>
        {scriptText.length} / 2000
      </MDTypography>
      <MDBox mb={2} position="relative">
        <MDInput
          label="Script"
          placeholder="Welcome to the land of Jellybean, where heroes are born and legends find their voice. Need a dramatic monologue, a quirky NPC conversation, or intense battle commands? Start creating now!"
          rows={10}
          multiline
          fullWidth
          value={scriptText}
          onChange={(e) => setScriptText(e.target.value)} // Update script text
          inputRef={inputRef} // Attach the reference to the input
          style={{ paddingBottom: "50px" }} // Ensure there's space at the bottom for the button
        />
        {/* Position the button at the bottom right */}
        <InputAdornment
          position="end"
          style={{
            position: "absolute",
            bottom: "35px",
            right: "0px", // Adjust as needed for padding
            textAlign: "center", // Center-align the button and text
          }}
        >
          {/* Add the caption below the button */}
          <span style={{ marginTop: "4px", fontSize: "12px", color: "#000" }}>Rewrite with AI</span>
          <IconButton
            color="info"
            onClick={handleRewriteClick}
            disabled={rewriting}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {rewriting ? <CircularProgress size={24} /> : <RefreshIcon />}
          </IconButton>
        </InputAdornment>
        {/* Position the button at the bottom right */}
        {originalScriptText && (
          <InputAdornment
            position="end"
            style={{
              position: "absolute",
              bottom: "35px",
              right: "125px", // Adjust as needed for padding
              textAlign: "center", // Center-align the button and text
            }}
          >
            {/* Add the caption below the button */}
            <span style={{ marginTop: "4px", fontSize: "12px", color: "#000" }}>Undo</span>
            <IconButton
              color="info"
              onClick={() => setScriptText(originalScriptText)} // Restore original text
              disabled={rewriting}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UndoIcon />
            </IconButton>
          </InputAdornment>
        )}
      </MDBox>
      {simpleMode && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {/* Pitch Slider */}
            <MDBox mb={3} md={6} p={1}>
              <MDTypography variant="h6" gutterBottom>
                Pitch
              </MDTypography>
              <MDTypography variant="subtitle2" gutterBottom sx={{ fontSize: "0.9rem" }}>
                Match the voice to the character: low pitch for depth and emotion, high pitch for
                energy and joy.
              </MDTypography>
              <Slider
                sx={{ width: "75%", marginLeft: "24px" }}
                value={pitch}
                min={-18}
                max={18}
                step={1}
                onChange={(e, newValue) => setPitch(newValue)} // Update pitch value
                valueLabelDisplay="off"
                aria-labelledby="pitch-slider"
                marks={pitchMarks}
              />
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            {/* Pace Slider */}
            <MDBox mb={3} md={6} p={1}>
              <MDTypography variant="h6" gutterBottom>
                Pace
              </MDTypography>
              <MDTypography variant="subtitle2" gutterBottom sx={{ fontSize: "0.9rem" }}>
                Modify the speed to match your desired delivery style or narrative flow.
              </MDTypography>
              <Slider
                value={pace}
                min={0.5}
                max={1.5}
                step={0.05}
                onChange={(e, newValue) => setPace(newValue)} // Update pace value
                valueLabelDisplay="auto"
                aria-labelledby="pace-slider"
                marks={paceMarks}
              />
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            {/* Volume Slider */}
            <MDBox mb={3} md={6} p={1}>
              <MDTypography variant="h6" gutterBottom>
                Volume
              </MDTypography>
              <MDTypography variant="subtitle2" gutterBottom sx={{ fontSize: "0.9rem" }}>
                Adjust the loudness to ensure clarity and balance within your audio mix
              </MDTypography>
              <Slider
                value={volume}
                min={-6}
                max={6}
                step={0.1}
                onChange={(e, newValue) => setVolume(newValue)} // Update volume value
                valueLabelDisplay="auto"
                aria-labelledby="volume-slider"
                valueLabelFormat={(value) => `${value} db`}
                marks={volumeMarks}
              />
            </MDBox>
          </Grid>
        </Grid>
      )}
      {/* Place dropdowns side by side */}
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <MDBox mb={2}>
            <MDInput
              label="Language"
              select
              id="languageSelect"
              value={selectedLanguage} // This would be a state variable to hold the selected language
              onChange={(e) => setSelectedLanguage(e.target.value)} // Handler to update the selected language
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value=""></option>
              {sortedLanguages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </MDInput>
          </MDBox>
        </Grid>
      </Grid>
      {/* Place dropdowns side by side */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* Dropdown for selecting gender */}
          <MDBox mb={2}>
            <MDInput
              label="Gender"
              select
              id="genderSelect"
              value={selectedGender}
              onChange={handleGenderChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value=""></option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </MDInput>
          </MDBox>
        </Grid>
        <Grid item xs={6}>
          {/* Dropdown for selecting accent */}
          <MDBox mb={2}>
            <MDInput
              label="Accent"
              select
              id="accentSelect"
              value={selectedAccent}
              onChange={handleAccentChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value=""></option>
              {[...new Set(voices.map((voice) => voice.accent))].map((accent) => (
                <option key={accent} value={accent}>
                  {accent}
                </option>
              ))}
            </MDInput>
          </MDBox>
        </Grid>
      </Grid>
      {/* Place dropdowns side by side */}
      <Grid container spacing={2}>
        {/* First Dropdown - Voice Selection (filtered) */}
        <Grid item xs={12}>
          <MDBox mb={2}>
            {/*<MDInput
              label="Voice"
              select
              id="voiceSelect"
              value={selectedVoice} // Capture the selected voice
              onChange={(e) => setSelectedVoice(e.target.value)} // Update voice selection
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value=""></option>
              {filteredVoices.map((voice) => {
                // Extract styles as a comma-separated string
                const styles = voice.voice_styles.map((style) => style.name).join(", ");
                return (
                  <option key={voice.uuid} value={voice.uuid}>
                    {`${voice.name}`}
                     (${voiceAgeLabelMapping[voice.voiceAge]}, ${styles})
                  </option>
                );
              })}
            </MDInput>*/}
            <Autocomplete
              id="voiceSelect"
              options={filteredVoices}
              getOptionKey={(option) => option.uuid}
              getOptionLabel={(option) => option.name}
              noOptionsText="No Voices Found"
              disablePortal={true}
              onChange={(e, opt, reason) => {
                if (reason === "selectOption" && !!opt) {
                  setSelectedVoice(opt.uuid);
                } else {
                  setSelectedVoice("");
                }
              }}
              renderOption={(optProps, option) => {
                const { key, ...optionProps } = optProps;
                return (
                  <Box key={key} component="li" {...optionProps}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100% !important",
                      }}
                    >
                      <Box component="div">
                        <MDTypography variant="body2">{option.name}</MDTypography>
                      </Box>

                      <Box
                        component="div"
                        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                      >
                        <MDBadge
                          badgeContent={option.voiceAge}
                          variant="contained"
                          size="sm"
                          container
                        />
                        {option.voice_styles.map((style) => (
                          <MDBadge
                            key={style.uuid}
                            badgeContent={style.name}
                            variant="contained"
                            size="sm"
                            container
                            sx={{ marginLeft: "4px" }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Voice"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    sx: { paddingTop: "0 !important" },
                  }}
                />
              )}
            />
          </MDBox>
        </Grid>
        {/* Second Dropdown - Voice Style Selection */}
        <Grid item xs={6}>
          <MDBox mb={2}>
            <MDInput
              label="Voice Style"
              select
              id="voiceStyleSelect"
              value={selectedVoiceStyle}
              onChange={handleVoiceStyleChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              <option value=""></option>
              {voiceStyles.map((style) => (
                <option key={style.uuid} value={style.speaker_id}>
                  {`${style.name}`}
                </option>
              ))}
            </MDInput>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox mt={1} mb={1}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={handleGenerateClick}
          disabled={loading} // Disable button while loading
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Generate"}
          {/* Show loading icon */}
        </MDButton>
        {generating && (
          <span style={{ fontSize: "0.875rem", fontStyle: "italic", color: "#555" }}>
            Hang tight—your masterpiece is brewing in the Jellybean forge!
          </span>
        )}
      </MDBox>
      {/* Audio player */}
      {audioUrl && (
        <MDBox mt={2}>
          <AudioPlayer
            autoPlay
            src={audioUrl}
            onPlay={(e) => console.log("Playing")}
            // Customize more options below
            showJumpControls={false} // Hide forward and backward jump controls
            customAdditionalControls={[]} // Hide extra controls
            customVolumeControls={[]} // Hide volume controls if needed
            layout="horizontal" // Set the layout style
            className="custom-audio-player"
            // You can add more props to customize the player further
          />
        </MDBox>
      )}
    </MDBox>
  );

  const renderExploreTab = (
    <MDBox>
      <MDTypography variant="h6" gutterBottom>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Voice Library
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={tableData}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDTypography>
      {/* Audio player */}
      {audioUrl && activeTab === 0 && (
        <MDBox mt={2}>
          <AudioPlayer
            autoPlay
            src={audioUrl}
            onPlay={(e) => console.log("Playing")}
            // Customize more options below
            showJumpControls={false} // Hide forward and backward jump controls
            customAdditionalControls={[]} // Hide extra controls
            customVolumeControls={[]} // Hide volume controls if needed
            layout="horizontal" // Set the layout style
            className="custom-audio-player"
            // You can add more props to customize the player further
          />
        </MDBox>
      )}
    </MDBox>
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={lgValue}>
              <Card sx={{ height: "100%", boxShadow: "none" }}>
                <MDBox
                  pt={4}
                  px={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Generate and Explore tabs"
                  >
                    <Tab label="Generate Voice" />
                    <Tab label="Explore Voice Library" />
                  </Tabs>
                  {activeTab === 0 && (
                    <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                      <span style={{ fontSize: "15px", marginRight: "8px" }}>Simple</span>
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Available only for Indie and Pro.&nbsp;
                            <Button
                              href="https://www.jellybeanai.co/pricing"
                              target="_blank"
                              rel="noopener noreferrer"
                              variant="contained"
                              color="primary"
                              sx={{ textTransform: "none", color: "#fff" }}
                            >
                              Upgrade now
                            </Button>
                          </Typography>
                        }
                        arrow
                      >
                        <span>
                          <Switch
                            checked={simpleMode}
                            onChange={handleSimpleMode}
                            disabled={["Free", "Solo Monthly", "Solo Yearly"].includes(
                              localStorage.getItem("subscriptionTier")
                            )}
                          />
                        </span>
                      </Tooltip>
                      <span style={{ fontSize: "15px", marginLeft: "8px" }}>Advanced</span>
                    </MDBox>
                  )}
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  {activeTab === 0 && renderGenerateTab}
                  {activeTab === 1 && renderExploreTab}
                </MDBox>
              </Card>
            </Grid>
            {activeTab === 0 && (
              <Grid item xs={12} md={6} lg={3}>
                {/* Pass the fetched history data to History component */}
                <History
                  historyItems={historyData}
                  handlePlayClick={handlePlayClick}
                  handleDownloadClick={handleDownloadClick}
                  itemIdKey="job_id"
                  itemTimeKey="submission_time"
                  sectionName="My Voice Assets"
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Render success and error snackbars */}
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

// Setting default values for the props of VoxStudio
VoxStudio.defaultProps = {
  light: false,
};

// Typechecking props for the VoxStudio
VoxStudio.propTypes = {
  light: PropTypes.bool,
};

export default VoxStudio;
