/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { WelcomePopup } from "layouts/profile/components/WelcomePopup";

import { useAuth0 } from "@auth0/auth0-react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Link from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TrialReminderModal from "components/TrialReminderModal";

// Jellybean React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";

import React, { useEffect, useState } from "react";
import axios from "axios";
import ApplicationSettings from "./components/ApplicationSettings";
import SubscriptionDetails from "./components/SubscriptionDetails";
import ProfileAssets from "./components/ProfileAssets";
import MDButton from "components/MDButton";

import MDSnackbar from "components/MDSnackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import WaitingListPopup from "./components/WaitingListPopup";

function Overview() {
  const { user, getAccessTokenSilently } = useAuth0(); // Fetch user from Auth0
  const [userDetails, setUserDetails] = useState({});
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showWaitingListPopup, setShowWaitingListPopup] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [responseMessage, setResponseMessage] = useState(""); // State to store the API response message
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="notifications"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Notification"
      content={responseMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const handleSubmitCode = async (code) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/validate-waiting-list-code`,
        { code },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.valid) {
        setResponseMessage(response.data.message);
        openSuccessSB();

        // Reload the page to update the UI
        window.location.reload();
      } else {
        setResponseMessage(response.data.message);
        openErrorSB();
      }
    } catch (error) {
      setResponseMessage("Error validating waiting list code: " + error.message);
      openErrorSB();
    }
  };

  const handleRequestNewCode = async (requestDetails) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/request-waiting-list-code`,
        { requestDetails },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setResponseMessage(response.data.message);
      // openSuccessSB();
    } catch (error) {
      setResponseMessage("Error requesting waiting list code: " + error.message);
      openErrorSB();
    }
  };

  const handleWelcomeClose = async () => {
    setShowWelcomePopup(false);
    setUser(userDetails.name, userDetails.studioName, userDetails.dateOfBirth, true);
  };

  // Fetch and Update User API calls
  const getUser = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      // ${process.env.REACT_APP_API_URL}
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      console.log(response.data);

      if (response.data.message === "User data not found") {
        await createUser();
        setShowWaitingListPopup(true);
      } else {
        if (!response.data.approved) {
          setShowWaitingListPopup(true);
        } else {
          setUserDetails(response.data);
          setIsEligible(true);

          // Check if the welcome message has already been shown
          if (!response.data.welcome) {
            setShowWelcomePopup(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const createUser = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      const created = new Date(user.updated_at);
      const ended = new Date(user.updated_at);
      ended.setDate(ended.getDate() + 5);

      // Subscription details are hard-coded as per the "Free" tier for now
      const payload = {
        name: user.nickname,
        email: user.email,
        studio_name: "",
        dob: "",
        subscription_tier: "Free",
        subscription_start_date: created.toISOString(),
        subscription_end_date: ended.toISOString(),
        beans_allocated: 1200,
        beans_balance: 1200,
        beans_carryover: 0,
        features: {},
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setUserDetails(response.data);
    } catch (error) {
      console.error("Error creating user details:", error);
    }
  };

  const setUser = async (userName, studioName, dob, welcome) => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE, // The audience for the API (from Auth0)
      });

      if (userName === "") {
        userName = user.nickname;
      }

      const payload = {
        name: userName,
        email: user.email,
        studio_name: studioName,
        dob: dob,
        welcome: welcome,
      };

      console.log("payload");
      console.log(payload);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      });

      setUserDetails(response.data);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div>
      <Backdrop open={!isEligible} style={{ zIndex: 999, color: "#fff" }} />
      <WaitingListPopup
        open={showWaitingListPopup}
        onSubmitCode={handleSubmitCode}
        requestNewCode={handleRequestNewCode}
      />
      {/* Render success and error snackbars */}
      {renderSuccessSB}
      {renderErrorSB}
      {isEligible && (
        <div>
          <DashboardLayout>
            <DashboardNavbar />
            <Header userName={userDetails?.name || user.nickname}>
              <MDBox mt={5} mb={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={6} p={1}>
                    <SubscriptionDetails
                      tier={userDetails.subscription_tier}
                      startDate={userDetails.subscription_start_date}
                      endDate={userDetails.subscription_end_date}
                      beansAllocated={userDetails.beans_allocated}
                      beansBalance={userDetails.beans_balance}
                    />
                  </Grid>

                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={6}
                    p={1}
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
                  >
                    <ProfileInfoCard
                      title="profile information"
                      description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                      info={{
                        fullName: userDetails?.name || user.nickname,
                        email: user.email,
                        studioName: userDetails?.studio_name || "",
                        dateOfBirth: userDetails?.dob || "",
                      }}
                      social={[
                        {
                          link: "https://www.facebook.com/CreativeTim/",
                          icon: <FacebookIcon />,
                          color: "facebook",
                        },
                        {
                          link: "https://twitter.com/creativetim",
                          icon: <TwitterIcon />,
                          color: "twitter",
                        },
                        {
                          link: "https://www.instagram.com/creativetimofficial/",
                          icon: <InstagramIcon />,
                          color: "instagram",
                        },
                      ]}
                      actionTooltip="Edit Profile"
                      shadow={false}
                      updateUserDetails={setUser}
                    />

                    <ApplicationSettings />
                  </Grid>
                </Grid>
              </MDBox>
              {userDetails?.subscription_tier === "Free" && (
                <MDBox mt={5} mb={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TrialReminderModal subscriptionEnd={userDetails?.subscription_end_date} />
                    </Grid>
                  </Grid>
                </MDBox>
              )}
            </Header>
            <ProfileAssets userName={userDetails?.name || user.nickname} />
            <Footer />
            {/* Welcome Message Dialog */}
            <Dialog open={showWelcomePopup} onClose={handleWelcomeClose}>
              <DialogTitle>Welcome to JellyBean!</DialogTitle>
              <DialogContent>
                <p>
                  Were thrilled to have you onboard. You can start free for 5 days with limited
                  access to the platform or upgrade today to get access to our power packed voice,
                  SFX & music generation tools. View all pricing{" "}
                  <a
                    href="https://www.jellybeanai.co/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1A73E8", textDecoration: "none" }}
                  >
                    here
                  </a>
                  .
                </p>
                <p>&nbsp;</p>
                <p>
                  <i>
                    By continuing, you agree to be contacted by our team for feedback to help us
                    improve your experience.
                  </i>
                </p>
              </DialogContent>
              <DialogActions>
                <MDButton
                  onClick={() => window.open("https://www.jellybeanai.co/pricing", "_blank")}
                  color="primary"
                >
                  View Pricing & Upgrade
                </MDButton>
                <MDButton onClick={handleWelcomeClose} color="primary">
                  Continue With Limited Free Acess
                </MDButton>
              </DialogActions>
            </Dialog>
          </DashboardLayout>
        </div>
      )}
    </div>
  );
}

export default Overview;
