/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useAuth0 } from "@auth0/auth0-react";

// Jellybean React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Jellybean React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Paypall() {
  const { planId } = useParams();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const { user } = useAuth0();
  const navigate = useNavigate();

  const handleSuccessClose = () => {
    setShowSuccessDialog(false);
    window.location.href = "/profile";
  };

  const razorpayUrlMapping = {
    [process.env.REACT_APP_SOLO_MONTHLY_ID]: process.env.REACT_APP_RZP_SOLO_MONTHLY_URL,
    [process.env.REACT_APP_INDIE_MONTHLY_ID]: process.env.REACT_APP_RZP_INDIE_MONTHLY_URL,
    [process.env.REACT_APP_PRO_MONTHLY_ID]: process.env.REACT_APP_RZP_PRO_MONTHLY_URL,
    [process.env.REACT_APP_SOLO_YEARLY_ID]: process.env.REACT_APP_RZP_SOLO_YEARLY_URL,
    [process.env.REACT_APP_INDIE_YEARLY_ID]: process.env.REACT_APP_RZP_INDIE_YEARLY_URL,
    [process.env.REACT_APP_PRO_YEARLY_ID]: process.env.REACT_APP_RZP_PRO_YEARLY_URL,
  };

  const planMapping = {
    [process.env.REACT_APP_SOLO_MONTHLY_ID]: "Solo Monthly",
    [process.env.REACT_APP_SOLO_YEARLY_ID]: "Solo Yearly",
    [process.env.REACT_APP_INDIE_MONTHLY_ID]: "Indie Monthly",
    [process.env.REACT_APP_INDIE_YEARLY_ID]: "Indie Yearly",
    [process.env.REACT_APP_PRO_MONTHLY_ID]: "Pro Monthly",
    [process.env.REACT_APP_PRO_YEARLY_ID]: "Pro Yearly",
  };

  useEffect(() => {
    let isMounted = true;

    // Check user's location
    fetch("https://ipapi.co/json/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Location service not available");
        }
        return response.json();
      })
      .then((data) => {
        if (!isMounted) return;

        console.log(data);

        if (data.country === "IN") {
          const redirectUrl = razorpayUrlMapping[planId];
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            setLocationError(true);
            console.error("Invalid plan ID for Razorpay redirect:", planId);
          }
          return;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error checking location:", error);
        if (isMounted) {
          setLocationError(true);
          setIsLoading(false);
        }
      });

    // Only load PayPal if we're not in India
    if (!isLoading && !locationError) {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
      script.addEventListener("load", () => {
        if (window.paypal && isMounted) {
          window.paypal
            .Buttons({
              style: {
                shape: "rect",
                color: "gold",
                layout: "vertical",
                label: "subscribe",
              },
              createSubscription: function (data, actions) {
                return actions.subscription.create({
                  plan_id: planId,
                  custom_id: user?.email,
                });
              },
              onApprove: function (data, actions) {
                setShowSuccessDialog(true);
              },
            })
            .render("#paypal-button-container");
        }
      });
      document.body.appendChild(script);

      return () => {
        isMounted = false;
        document.body.removeChild(script);
      };
    }

    return () => {
      isMounted = false;
    };
  }, [planId, user, isLoading, locationError]);

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <MDBox p={3} textAlign="center">
                  <MDTypography variant="h5">Loading...</MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }

  if (locationError) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <MDBox p={3} textAlign="center">
                  <MDTypography variant="h5" color="error">
                    Unable to verify location. Please try again later.
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Subscription Plan: {planMapping[planId] || planId}
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <div id="paypal-button-container" />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog
        open={showSuccessDialog}
        onClose={handleSuccessClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent>
          <MDBox p={3} color="white">
            <MDBox display="flex" alignItems="center" mb={3}>
              <MDTypography variant="h3" color="white" mr={1}>
                🚀
              </MDTypography>
              <MDTypography variant="h5" color="white" fontWeight="bold">
                Subscription Successful!
              </MDTypography>
            </MDBox>

            <MDTypography variant="body1" color="white" mb={4}>
              Your subscription has been successfully activated. You will be redirected to your
              profile.
            </MDTypography>

            <MDBox display="flex" justifyContent="flex-end" gap={2}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={handleSuccessClose}
                startIcon={<Icon>check</Icon>}
              >
                Continue
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Paypall;
