import MusicNoteIcon from "@mui/icons-material/MusicNote";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import MovieIcon from "@mui/icons-material/Movie";

export const preloadedPrompts = [
  {
    icon: <MusicNoteIcon />,
    text: "Relaxing acoustic guitar playing in a cozy coffee shop",
  },
  {
    icon: <SportsEsportsIcon />,
    text: "8-bit chiptune music for a retro pixel-art adventure game",
  },
  {
    icon: <NightlifeIcon />,
    text: "Upbeat synthwave track for a high-speed cyberpunk racing game",
  },
  {
    icon: <MovieIcon />,
    text: "Haunting choir and orchestral score for a dark fantasy horror scene",
  },
  {
    icon: <LocalCafeIcon />,
    text: "Soft piano and strings for a heartwarming sunset ending in a film",
  },
];
