import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function IntercomMessenger() {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    // Make sure window.Intercom is available
    if (typeof window.Intercom === "function") {
      if (isAuthenticated && user) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "i4l1ew1o",
          user_id: user.sub,
          name: user.name,
          email: user.email,
          created_at: Math.floor(new Date(user.updated_at).getTime() / 1000),
          hide_default_launcher: false,
        });
      } else {
        // For anonymous users
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "i4l1ew1o",
          hide_default_launcher: false,
        });
      }
    }

    return () => {
      if (typeof window.Intercom === "function") {
        window.Intercom("shutdown");
      }
    };
  }, [user, isAuthenticated]);

  return null;
}

export default IntercomMessenger;
