import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

function TrialReminderModal({ subscriptionEnd }) {
  const [open, setOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [message, setMessage] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    if (!subscriptionEnd) return;

    const endDate = new Date(subscriptionEnd);
    const now = new Date();
    const diffTime = endDate - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setDaysLeft(diffDays);

    if (diffDays <= 0) {
      setMessage(
        "Game over… or is it? Your free access to Jellybean has ended, but you can get back in action with a paid plan. Upgrade now and keep creating!"
      );
      setIcon("⏰");
    } else if (diffDays === 1) {
      setMessage(
        "Last chance! Your Jellybean AI trial ends tomorrow. Don’t lose access—unlock premium features by upgrading now!"
      );
      setIcon("⚠️");
    } else if (diffDays <= 2) {
      setMessage(
        "Your free trial is almost up! Keep creating with AI-powered voice, SFX, and music—upgrade today and stay in the game! 🎮"
      );
      setIcon("⏳");
    } else {
      setMessage(
        "Time's ticking! ⌛  Make the most of your Jellybean limited acess trial while you can. Need more? Upgrade now for uninterrupted access!"
      );
      setIcon("🚀");
    }

    // Show modal only if 3 or fewer days left
    if (diffDays <= 3) {
      setOpen(true);
    }
  }, [subscriptionEnd]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpgrade = () => {
    window.open("https://www.jellybeanai.co/pricing", "_blank");
    handleClose();
  };

  if (daysLeft > 3) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent>
        <MDBox p={3} color="white">
          <MDBox display="flex" alignItems="center" mb={3}>
            <MDTypography variant="h3" color="white" mr={1}>
              {icon}
            </MDTypography>
            <MDTypography variant="h5" color="white" fontWeight="bold">
              {daysLeft <= 0
                ? "Free Access Expired!"
                : `${daysLeft} Day${daysLeft !== 1 ? "s" : ""} Left On Your Free Account`}
            </MDTypography>
          </MDBox>

          <MDTypography variant="body1" color="white" mb={4}>
            {message}
          </MDTypography>

          <MDBox display="flex" justifyContent="flex-end" gap={2}>
            <MDButton variant="text" color="white" onClick={handleClose}>
              Later
            </MDButton>
            <MDButton
              variant="gradient"
              color="light"
              onClick={handleUpgrade}
              startIcon={<Icon>upgrade</Icon>}
            >
              Upgrade Now
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}

// Setting default values for the props of TrialReminderModal
TrialReminderModal.defaultProps = {
  subscriptionEnd: null,
};

// Typechecking props for the TrialReminderModal
TrialReminderModal.propTypes = {
  subscriptionEnd: PropTypes.string,
};

export default TrialReminderModal;
