/**
=========================================================
* Jellybean React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Jellybean React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Jellybean React layouts
import Profile from "layouts/profile";
import VoxStudio from "layouts/SoundDesign/VoxStudio";
import SFXStudio from "layouts/SoundDesign/SFXStudio";
import MusicStudio from "layouts/SoundDesign/MusicStudio";

// @mui icons
import Icon from "@mui/material/Icon";
import SceneToSound from "layouts/Tools/SceneToSound";
import SubscriptionGuard from "components/SubscriptionGuard";
import Paypall from "layouts/Paypall";

const routes = [
  {
    type: "title",
    title: "Sound Design",
    key: "sound-design",
  },
  {
    type: "collapse",
    name: "Vox Studio",
    key: "vox-studio",
    icon: <Icon fontSize="small">record_voice_over</Icon>,
    route: "/vox-studio",
    component: (
      <SubscriptionGuard>
        <VoxStudio />
      </SubscriptionGuard>
    ),
  },
  {
    type: "collapse",
    name: "SFX Studio",
    key: "sfx-studio",
    icon: <Icon fontSize="small">graphic_eq</Icon>,
    route: "/sfx-studio",
    component: (
      <SubscriptionGuard>
        <SFXStudio />
      </SubscriptionGuard>
    ),
  },
  {
    type: "collapse",
    name: "Music Studio",
    key: "music-studio",
    icon: <Icon fontSize="small">queue_music</Icon>,
    route: "/music-studio",
    component: (
      <SubscriptionGuard>
        <MusicStudio />
      </SubscriptionGuard>
    ),
  },
  {
    type: "divider",
    key: "divider",
  },
  {
    type: "title",
    title: "Tools",
    key: "tools",
  },
  {
    type: "collapse",
    name: "Scene To Sound",
    key: "scene-to-sound",
    icon: <Icon fontSize="small">audiotrack</Icon>,
    route: "/scene-to-sound",
    component: <SceneToSound />,
  },
  {
    type: "divider",
    key: "divider-1",
  },
  {
    type: "divider",
    key: "divider-2",
  },
  {
    type: "title",
    title: "Resources",
    key: "Resources",
  },
  {
    type: "collapse",
    name: "Billing & Subscription",
    key: "billing-subscription",
    icon: <Icon fontSize="small">payments</Icon>,
    href: "https://www.jellybeanai.co/pricing",
  },
  {
    type: "collapse",
    name: "Discord Community",
    key: "Discord Community",
    icon: <Icon fontSize="small">forum</Icon>,
    href: "https://discord.gg/4wgp8eqD",
  },
  {
    type: "collapse",
    name: "Whatsapp Support",
    key: "Whatsapp Support",
    icon: <Icon fontSize="small">chat</Icon>,
    href: "https://chat.whatsapp.com/LGTUCFTgvZdI3RnXAYUKXG",
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "Contact Us",
    icon: <Icon fontSize="small">contact_mail</Icon>,
    href: "mailto:help@jellybeanai.co",
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    icon: <Icon fontSize="small">feedback</Icon>,
    href: "mailto:feedback@jellybeanai.co",
  },
  {
    type: "divider",
    key: "divider-3",
  },
  {
    key: "profile",
    route: "/profile",
    component: <Profile />,
  },
  {
    key: "paypall",
    route: "/paypall/:planId?",
    component: <Paypall />,
  },
];

export default routes;
